import React, { useState } from 'react';
import propTypes from 'prop-types';
import './style.scss';
import { Link } from 'react-scroll';

function Navbar({ logo }){
  const { innerWidth: width } = window;
  const [isMenuOpen, toggleMenu] = useState(width >= 1060);

  function handleClick(e) {
    e.preventDefault();
    toggleMenu(!isMenuOpen);
  }

  return (
    <header className='navbar'>
      <nav className='row'>
        <Link to='section1' spy={true} smooth={true} offset={-60} duration={500} className='brand'>
          <img src={logo} alt='logo'/>
        </Link>
        <div className='menu-right'>
          <button className='burger-menu' onClick={handleClick}>
            <i className='fa fa-bars' aria-hidden='true'></i>
          </button>
          <div className='menu' style={{display: isMenuOpen ? '' : 'none'}}>
            <Link to='section1' spy={true} smooth={true} offset={-60} duration={500}>Главная</Link>
            <Link to='section2' spy={true} smooth={true} offset={-60} duration={500}>Компания</Link>
            <Link to='section3' spy={true} smooth={true} offset={-60} duration={500}>Платформа 'Nertus'</Link>
            <Link to='section4' spy={true} smooth={true} offset={-60} duration={500}>Спонсоры</Link>
          </div>
        </div>
      </nav>
    </header>
  )
}

Navbar.propTypes = {
  children: propTypes.node,
}

Navbar.defaultProps = {
}

export default React.memo(Navbar);
