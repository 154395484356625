import { useRef, useState, memo } from 'react';
import propTypes from 'prop-types';
import { useOnScroll } from '../../hooks/useOnScroll';
import './style.scss';

function Parallax({
  src = '',
  alt = '',
  height,
  children,
  fixed = false,
  speed = 1,
  clamp = false,
  debug = false,
  text,
  id
 }){
  const box = useRef(null);
  const img = useRef(null);
  const [ΔY, setΔY] = useState(0);

  useOnScroll((scrollY, winHeight) => {
    if (fixed) return;
    if (!box.current) return;
    if (!img.current) return;

    const boxRect = box.current.getBoundingClientRect();

    const winBottom = scrollY + winHeight;
    const boxTop = boxRect.top + scrollY;
    const boxBottom = boxRect.bottom + scrollY;
    const boxHeight = boxRect.height;
    const pct = (clampVal(winBottom, boxTop, boxBottom + winHeight) - boxTop) / (boxHeight + winHeight);

    const imgHeight = img.current.offsetHeight;

    if (imgHeight < boxHeight) {
      console.warn(
        `parallax effect doesn't work if image is smaller than the bounding box. imgHeight=${imgHeight} boxHeight=${boxHeight}`,
      );
      return;
    }

    const diff = imgHeight - boxHeight;
    const deltaY = (0 - diff - diff * speed) * 0.5 + diff * pct * speed;

    if (clamp) {
      setΔY(clampVal(deltaY, -diff, 0));
    } else {
      setΔY(deltaY);
    }

    if (debug) console.log({
      pct,
      ΔY,
    });
  });

  return (
    <div ref={box} className='image-box' style={{ height: height || undefined }} id={id}>
      {fixed ? (
        <div
          className='image-bg dark-overlay'
          style={{ backgroundImage: `url(${src})`, height: height || undefined }}
        />
      ) : (
        <img
          ref={img}
          className='image-behind'
          src={src}
          alt={alt}
          style={{
            transform: `translate(0, ${ΔY}px)`
          }}
        />
      )}
      {Boolean(text) && <div className='image-content'><h1>{text}</h1></div>}
    </div>
  )
}

const clampVal = (num = 0, min = 0, max = 0) => {
  return Math.max(min, Math.min(num, max));
};

Parallax.propTypes = {
  children: propTypes.node,
}

Parallax.defaultProps = {
}

export default memo(Parallax);
