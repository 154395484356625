import React, { useState, useEffect, useCallback, useRef } from 'react';
import propTypes from 'prop-types';
import './style.scss';

function SlidingTrack({ 
  numOfImg = 0,
  imageWidth = 500,
  imageHeight = 520,
  gapWidth = 40,
  massOfPhoto,
}){
  const imgs = [];
  numOfImg = massOfPhoto.length;

  for (let i = 0; i < numOfImg; i++) {
    imgs.push(<img key={i} alt='' className='image' src={massOfPhoto[i]} draggable='false' ref={el => imageRefs.current[i] = el}/>);
  }

  const [scrollX, setScrollX] = useState(0);
  const [maxScrollX, setMaxScrollX] = useState(100);
  const [touchX, setTouchX] = useState(0);
  const [imgWidth, setImgWidth] = useState(imageWidth);

  const divWidth = (imgWidth + gapWidth) * numOfImg - gapWidth;
  const halfImgPercentage = imgWidth / 2 / (divWidth) * 100;
  const distBetweenImg = maxScrollX * (50 - halfImgPercentage) / 100 / (numOfImg - 1) * 2;

  useEffect(() => { // Обновление максимального значения скролла при изменении размера экрана
    function updateMaxScrollX() {
      setMaxScrollX(window.innerWidth / 2); // Максимальное значение - половина ширины экрана
      if (window.innerWidth <= 500) setImgWidth(window.innerWidth * 0.95);
      // console.log(imgWidth)
    }
    updateMaxScrollX();
    window.addEventListener('resize', updateMaxScrollX);
    return () => window.removeEventListener('resize', updateMaxScrollX);
  }, []);

  const handleScroll = useCallback((e) => {
    setScrollX(prevScrollX => prevScrollX + e.movementX);
  }, []);

  const handleTouchScroll = useCallback((movementX) => {
    setScrollX(prevScrollX => prevScrollX + movementX);
  }, []);

  function handleMouseDown() {
    window.addEventListener('mousemove', handleScroll);
  }

  function handleMouseUp() {
    window.removeEventListener('mousemove', handleScroll);
    setScrollX(prevScrollX => Math.max(Math.min(prevScrollX, maxScrollX * (50 - halfImgPercentage) / 100), -maxScrollX * (50 - halfImgPercentage) / 100));
  }

  function handleTouchStart(e) {
    const touchDown = e.touches[0].clientX;
    setTouchX(touchDown);
  }

  function handleTouchMove(e) {
    const touchDown = touchX;

    if(touchDown === null) {
      return
    }

    const currentTouch = e.touches[0].clientX;
    const diff = touchDown - currentTouch;

    if (diff > 5) {
      handleTouchScroll(-distBetweenImg);
    }

    if (diff < -5) {
      handleTouchScroll(distBetweenImg);
    }

    setTouchX(null);
  }

  // function handleTouchMove(e) {
  //   const touchDown = touchX;
  //   const currentTouch = e.touches[0].clientX;
  //   const diff = currentTouch - touchDown;

  //   handleTouchScroll(diff);
  //   setTouchX(currentTouch);
  // }

  function handleTouchEnd() {
    setScrollX(prevScrollX => Math.max(Math.min(prevScrollX, maxScrollX * (50 - halfImgPercentage) / 100), -maxScrollX * (50 - halfImgPercentage) / 100));
  }

  const imageTrack = useRef(null);
  const imageRefs = useRef([null]);

  const nextPercentage = Math.max(Math.min((scrollX / maxScrollX) * 100, 50 -  halfImgPercentage), -50 + halfImgPercentage);
  // const nextPercentage = Math.max(Math.min((scrollX / maxScrollX) * 100, 50 - halfImgPercentage), -50 + halfImgPercentage);

  useEffect(() => {
    imageTrack.current.style.gap = `${gapWidth}px`;
    imageTrack.current.animate( // Плавная анимация карусели
      { transform: `translateX(${nextPercentage - 50}%)` },
      { duration: 1200, fill: 'forwards' }
    );

    for (let i = 0; i < numOfImg; i++) { // Плавная анимация картинок
      imageRefs.current[i].style.width = `${imgWidth}px`;
      imageRefs.current[i].style.height = `${imageHeight}px`;
      imageRefs.current[i].animate(
        { objectPosition: `${nextPercentage + (2 * i + 1) * halfImgPercentage + i * gapWidth / divWidth * 100}% center` },
        { duration: 1200, fill: "forwards" }
      )
    };
  }, [nextPercentage, numOfImg, imgWidth, imageHeight, gapWidth, halfImgPercentage, divWidth]);

  return (
    <div>
      {/* <p>Current maxScrollX: {maxScrollX}</p>
      <p>Current scroll position: {scrollX}</p>
      <p>Current nextPercentage: {nextPercentage}</p>
      <p>Current halfImgPercentage: {halfImgPercentage}</p>
      <p>Current minScroll: {maxScrollX * (50 - halfImgPercentage) / 100}</p> */}
      {/* <span className='span'></span> */}
      <section className='mouse-track'
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <div className='image-track' ref={imageTrack}>
          {imgs}
        </div>
      </section>
    </div>
  )
}

SlidingTrack.propTypes = {
  children: propTypes.node,
}

SlidingTrack.defaultProps = {
}

export default React.memo(SlidingTrack);
