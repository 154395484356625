import React from 'react';
import propTypes from 'prop-types';
import './style.scss';
import { Link } from 'react-scroll';

function Intro({ logo, background }){

  return (
    <div className='intro' style={{backgroundImage: `url(${background})`}}>
      <div className='logo-large'>
        <img src={logo} alt='logo-large'></img>
        <Link to={'section2'} smooth={true} offset={-60} duration={500}>
          <i className='fa fa-arrow-down' aria-hidden='true'></i>
        </Link>
      </div>
    </div>
  )
}

Intro.propTypes = {
  children: propTypes.node,
}

Intro.defaultProps = {
}

export default React.memo(Intro);
