import React from 'react';
import propTypes from 'prop-types';
import './style.scss';
import { Link } from 'react-scroll';

function Footer(){

  return (
    <footer className=''>
      <div className='footer'>
        <div className='row'>
          <ul>
            <li><Link to='section1'>Главная</Link></li>
            <li><Link to='section2'>Компания</Link></li>
            <li><Link to='section3'>Платформа 'Nertus'</Link></li>
            <li><Link to='section4'>Спонсоры</Link></li>
          </ul>
        </div>

        <div className='row'>
          NiProg Copyright © 2022-2023 NiProg - All rights reserved
        </div>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  children: propTypes.node,
}

Footer.defaultProps = {
}

export default React.memo(Footer);
