import React from 'react';
import propTypes from 'prop-types';
import './style.scss';

function Sponsors({ mainSponsor, massOfPhoto }){
  const imgs = [];
  const numOfImg = massOfPhoto.length;

  for (let i = 0; i < numOfImg; i++) {
    imgs.push(<img key={i} alt='' className='image' src={massOfPhoto[i]} draggable='false'/>);
  }

  return (
    <div className='sponsors'>
      <div className='another-sponsors'>
        <h2>Партнеры</h2>
        <div className='sponsors-img'>
          {/* {imgs} */}
          <a href='https://trionix-lab.ru/'><img alt='' className='image' src={massOfPhoto[0]} draggable='false'/></a>
          <a href='#'><img alt='' className='image' src={massOfPhoto[1]} draggable='false'/></a>
        </div>
      </div>
      <div className='main-sponsor'>
        <h2>Проект выполнен при поддержке <br/>«Фонда содействия инновациям» 
        в рамках федерального проекта <br/>«Платформа университетского технологического предпринимательства»</h2>
        <a href='https://univertechpred.ru/'><img alt='' className='image' src={mainSponsor} draggable='false'/></a>
      </div>
      
    </div>
  )
}

Sponsors.propTypes = {
  children: propTypes.node,
}

Sponsors.defaultProps = {
}

export default React.memo(Sponsors);
