import React from 'react';
import propTypes from 'prop-types';
import './style.scss';

function Projects(){

  return (
    <div className='projects container'>
      <hr></hr>
      <p>
        Роботизированная платформа повышенной проходимости «Нертус» - это воплощение смелых и передовых идей в области мобильной робототехники. Она представляет собой инновационное решение, которое переворачивает представление о возможностях роботов и открывает новые горизонты в их функциональности.
      </p>
      <p>
        Сердцем робота являются колеса с изменяемой геометрией, которые позволяют этой платформе преодолевать преграды и преодически менять свою форму, чтобы приспосабливаться к различным условиям и территориям. Но «Нертус» - это не просто робот, это надежный партнер и помощник, готовый взять на себя самые сложные задачи. Он может быть использован в различных сферах, от исследований и спасательных операций до промышленности и логистики. Его гибкость и адаптивность делают его незаменимым инструментом для достижения целей и решения сложных задач.
      </p>
    </div>
  )
}

Projects.propTypes = {
  children: propTypes.node,
}

Projects.defaultProps = {
}

export default React.memo(Projects);
