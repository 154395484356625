import React from 'react';
import propTypes from 'prop-types';
import './style.scss';

function Company({ logo }){

  return (
    <div className='company container'>
      <img className='' src={logo} alt=''></img>
      <hr></hr>
      <h4>
        Технологии - это мост, который соединяет нас с будущим, проложенный через постоянное развитие и инновации
      </h4>
      <hr></hr>
      <p>
        <b>Niprog Robotics</b> - это инновационный проект, стремящийся изменить мир через новаторские идеи и технологии. Мы нацелены на создание революционных решений, которые преобразуют отрасли и улучшают жизнь людей.
      </p>
      <p>
        В основе нашего стартапа лежит страсть к инновациям и стремление к постоянному развитию. Мы объединяем команду талантливых и преданных профессионалов, которые делят общую цель - создание продуктов и услуг, которые меняют правила игры.
      </p>
      <p>
        Наша компания также ценит партнерство и сотрудничество. Мы стремимся к установлению долгосрочных отношений с нашими клиентами, инвесторами и партнерами, чтобы вместе достичь великих результатов.
      </p>
    </div>
  )
}

Company.propTypes = {
  children: propTypes.node,
}

Company.defaultProps = {
}

export default React.memo(Company);
