import forSlidingtrack1 from './forSlidingTrack/20230829_174847.jpg';
import forSlidingtrack2 from './forSlidingTrack/20230829_174939.jpg';
import forSlidingtrack3 from './forSlidingTrack/20230829_175036.jpg';
import forSlidingtrack4 from './forSlidingTrack/20230829_125157.jpg';
import forSlidingtrack5 from './forSlidingTrack/20230829_175055.jpg';

import mainSponsor from './sponsorLogos/mainSponsor.png';

import sponsorLogos1 from './sponsorLogos/Logo_new.png';
import sponsorLogos2 from './sponsorLogos/logo_gradient.png';

import logoPurpleLong from './logos/logo_purple_long.png';
import logoPurpleShort from './logos/logo_purple_short.png';
import logoPinkLong from './logos/logo_pink_long.png';
import logoPinkShort from './logos/logo_pink_short.png';
import logoPinkShort1 from './logos/logo_pink_short1.png';
import logoWhiteShort from './logos/logo_white_short.png';

import parallaxBackground1 from './backgrounds/1611857365_4-p-vektornii-fon-tekhno-4.jpg';
import parallaxBackground2 from './backgrounds/1611857380_43-p-vektornii-fon-tekhno-49.jpg';
import parallaxBackground3 from './backgrounds/1611857346_28-p-vektornii-fon-tekhno-31.jpg';

import introBackground from './intro/20230829_125610.jpg';

const IMAGES = {
  forSlidingtrack: [forSlidingtrack1, forSlidingtrack2, forSlidingtrack3, forSlidingtrack4, forSlidingtrack5],
  mainSponsor: mainSponsor,
  sponsorLogos: [sponsorLogos1, sponsorLogos2],
  logos: [logoPinkLong, logoPinkShort, logoWhiteShort],
  parallaxBackgrounds: [parallaxBackground1, parallaxBackground2, parallaxBackground3],
  introBackground: introBackground
}

export default IMAGES;
