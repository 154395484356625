import { memo } from 'react';

import Wrapper from '../../components/wrapper';
import Navbar from '../../components/navbar';
import Intro from '../../components/intro';
import Parallax from '../../components/parallax';
import SlidingTrack from '../../components/slidingTrack';
import Sponsors from '../../components/sponsors';
import Footer from '../../components/footer';
import Company from '../../components/company';
import Projects from '../../components/projects';

import IMAGES from '../../assets/images/index.js';


function Main() {

  return (
    <>
      <Navbar logo={IMAGES.logos[0]}/>
      <Wrapper>
        <section id='section1'>
          <Intro logo={IMAGES.logos[1]} background={IMAGES.introBackground}/>
        </section>
        <section id='section2'>
          <Parallax src={IMAGES.parallaxBackgrounds[0]} alt="first img" height="250px" speed={2} clamp={true} text='Компания'/>
          <Company logo={IMAGES.logos[2]}/>
        </section>
        <section id='section3'>
          <Parallax src={IMAGES.parallaxBackgrounds[1]} alt="second img" height="250px" speed={2} clamp={true} text="Платформа 'Nertus'"/>
          <SlidingTrack massOfPhoto={IMAGES.forSlidingtrack} imageWidth={400} imageHeight={340}/>
          <Projects/>
        </section>
        <section id='section4'>
          <Parallax src={IMAGES.parallaxBackgrounds[2]} alt="second img" height="250px" speed={2} clamp={true} text='Спонсоры'/>
          <Sponsors mainSponsor={IMAGES.mainSponsor} massOfPhoto={IMAGES.sponsorLogos}/>
        </section>
        <Footer/>
      </Wrapper>
    </>
  )
}

export default memo(Main);
